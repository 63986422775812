import { graphql, Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useSiteSettings from '../hooks/useSiteSettings';
import Layout from '../layouts/Layout';
// import SearchForm from '../components/Search';
import Seo from '../components/Seo';
import { withArticles } from '../utils';

import Chat from '../assets/svg/chat.svg';
import FolderIcon from '../assets/svg/folder.svg';

const Help = (props) => {
  const { t } = useTranslation();
  const settings = useSiteSettings();
  const categories = props.data.categories.nodes.filter(withArticles);

  return (
    <Layout>
      <Seo
        title={settings.heading}
        description={settings.subheading}
        path="/help"
      />

      <div className="px-4 sm:px-6 mx-auto max-w-5xl">
        <div className="mb-9">
          <h1>{settings.heading}</h1>
          <h2>{settings.subheading}</h2>
        </div>

        {/* <div className="mx-auto mb-12 md:mb-9 max-w-full md:max-w-2xl">
          <SearchForm />
        </div> */}

        <div className="bg-white rounded-lg shadow-lg">
          {categories.map((category) => (
            <div
              key={category.slug}
              className="flex p-9 space-x-4 bg-white first:rounded-t last:rounded-b border border-b-0 last:border-b border-gray-200"
            >
              <FolderIcon className="w-7 h-7 leading-7" />
              <div>
                <h3 className="mt-0">
                  <Link to={`/help/${category.slug}/`} className="no-underline">
                    {category.name}
                  </Link>
                </h3>
                {category.description && (
                  <p className="last:mb-0">{category.description}</p>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="flex md:flex-row-reverse flex-wrap items-center mt-12">
          <div className="p-6 pb-0 md:pb-6 mx-auto w-3/5 md:w-1/2">
            <Chat
              alt={t('Chat')}
              className="z-50 mx-auto w-full md:w-3/5 h-auto"
            />
          </div>
          <div className="p-4 md:p-6 w-full sm:w-1/2">
            <h2 className="mb-3 text-3xl font-bold leading-none text-gray-800">
              Have a question or feedback?
            </h2>
            <p className="text-xl">Get in touch.</p>
            <a
              href="mailto:support@copybara.cc"
              className="btn btn-lg btn-outline"
            >
              {t('Contact us')}
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Help;

export const query = graphql`
  query {
    categories: allContentfulKbAppCategory {
      nodes {
        name
        description: previewDescription
        slug
        articles: kbapparticle {
          id
        }
      }
    }
  }
`;
